import React from "react";
import { AppShell, Navbar, Header, ActionIcon, Tooltip } from "@mantine/core";
import { WorkspaceNavbar } from "./components/WorkspaceNavbar";
import { IconQuestionMark } from "@tabler/icons";

export default function Workspace({ children }) {
	return (
		<AppShell
			padding="md"
			navbar={<WorkspaceNavbar />}
			styles={(theme) => ({
				main: {
					backgroundColor:
						theme.colorScheme === "dark"
							? theme.colors.dark[8]
							: theme.colors.gray[0],
				},
			})}
		>
			{children}
			<Tooltip label="Documentatie" position="left" withArrow>
				<ActionIcon
					size="xl"
					radius="xl"
					variant="filled"
					sx={{ position: "fixed", bottom: 20, right: 20 }}
					onClick={() =>
						window.open("https://docs.a2advies.be", "_blank").focus()
					}
				>
					<IconQuestionMark size={30} />
				</ActionIcon>
			</Tooltip>
		</AppShell>
	);
}
