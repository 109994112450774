import React from "react";
import { useApi } from "./contexts/APIContext";
import {
	DataGrid,
	highlightFilterValue,
	stringFilterFn,
	booleanFilterFn,
} from "mantine-data-grid";
import {
	MultiSelect,
	Container,
	Title,
	Text,
	Card,
	Group,
	Button,
	Drawer,
	TextInput,
	Slider,
	Stack,
	Box,
	Anchor,
	Tooltip,
	Loader,
	ActionIcon,
	ColorSwatch,
	useMantineTheme,
} from "@mantine/core";
import PlacesAutocomplete, {
	geocodeByAddress,
} from "react-places-autocomplete";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { functions } from "./firebase";
import { httpsCallable } from "firebase/functions";
import {
	IconAt,
	IconBriefcase,
	IconCheck,
	IconInfoCircle,
	IconMapPin,
	IconNumber,
	IconPencil,
	IconPhone,
	IconPlus,
	IconTrash,
} from "@tabler/icons";

export default function AlleBedrijven() {
	const theme = useMantineTheme();

	const [opened, setOpened] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState("");
	const [gmapsLoaded, setGmapsloaded] = React.useState(false);
	const [werfAdresObj, setWerfAdresObj] = React.useState({});
	const [address, setAddress] = React.useState("");
	const [selected, setSelected] = React.useState({});

	const { companies, setDossierFilterApp } = useApi();
	const navigate = useNavigate();

	React.useEffect(() => {
		window.initMap = () => setGmapsloaded(true);
		const gmapScriptEl = document.createElement(`script`);
		gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVCzR-OHB_QrsU0P6B83VPZlq9_mFrgP8&libraries=places&callback=initMap`;
		document
			.querySelector(`body`)
			.insertAdjacentElement(`beforeend`, gmapScriptEl);
	}, []);

	const form = useForm({
		initialValues: {
			email: "",
			naam: "",
			tel: "",
			btw: "",
			commissie: 10,
			facturatieAdres: "",
		},

		validate: (values) => ({
			email: /^\S+@\S+$/.test(values.email) ? null : "Ongeldig e-mailadres",
			naam: values.naam.length > 0 ? null : "Dit veld is verplicht",
			tel: values.tel.length > 0 ? null : "Dit veld is verplicht",
			btw: values.btw.length > 0 ? null : "Dit veld is verplicht",
		}),
	});

	React.useEffect(() => {
		if (Object.keys(selected).length > 0) {
			setOpened(true);
			form.setValues({
				email: selected.email,
				naam: selected.naam,
				tel: selected.tel,
				btw: selected.btw,
				commissie: selected.commissie,
				facturatieAdres: selected.facturatieAdres,
			});
		}
	}, [selected]);

	const handleNewCompany = async (values) => {
		setLoading(true);
		const submit = httpsCallable(functions, "createCompany");
		await submit(values)
			.then((e) => {
				if (!e.data.error) {
					setLoading(false);
					setOpened(false);
					form.reset();
					showNotification({
						title: "Bedrijf aangemaakt",
						message: `Bedrijf ${values.naam} is succesvol aangemaakt!`,
						icon: <IconCheck />,
						color: "teal",
					});
				} else {
					setLoading(false);
					setError(e.data.errorMessage);
				}
			})
			.catch((error) => console.log(error));
	};

	const handleEditCompany = async (values) => {
		setLoading(true);
		const submit = httpsCallable(functions, "editCompany");
		await submit({
			id: selected.id,
			...values,
		})
			.then((e) => {
				if (!e.data.error) {
					setLoading(false);
					setOpened(false);
					form.reset();
					showNotification({
						title: "Bedrijf bijgewerkt",
						message: `Bedrijf ${values.naam} is succesvol bijgewerkt!`,
						icon: <IconCheck />,
						color: "teal",
					});
				} else {
					setLoading(false);
					setError(e.data.errorMessage);
				}
			})
			.catch((error) => console.log(error));
	};

	const handleDeleteCompany = async () => {
		const deleteCompany = httpsCallable(functions, "deleteCompany");
		await deleteCompany({
			id: selected.id,
		}).then((e) => {
			setOpened(false);
			form.reset();
			showNotification({
				title: "Bedrijf verwijderd",
				message: `Bedrijf ${selected.naam} is succesvol verwijderd!`,
				icon: <IconTrash />,
				color: "teal",
			});
		});
	};

	const handlePhoneChange = (e) => {
		e.target.value = e.target.value.replace(/\s|\D/g, "");
		form.setFieldValue("tel", e.target.value);
	};

	const handleVATChange = (e) => {
		e.preventDefault();
		e.target.value = e.target.value.replace(/\s|\./g, "");
		form.setFieldValue("btw", e.target.value);
	};

	return (
		<>
			<Drawer
				opened={opened}
				onClose={() => {
					form.reset();
					setError("");
					setOpened(false);
					setSelected({});
				}}
				position="right"
				title={
					Object.keys(selected).length > 0 ? "Bewerk bedrijf" : "Nieuw bedrijf"
				}
				padding="xl"
				size="xl"
				styles={(theme) => ({
					title: {
						fontSize: "1.4rem",
						fontWeight: "700",
						color: theme.colors.blue[8],
					},
				})}
			>
				<form
					onSubmit={form.onSubmit((values) => {
						Object.keys(selected).length === 0
							? handleNewCompany(values)
							: handleEditCompany(values);
					})}
				>
					<Stack justify="flex-start" spacing="lg">
						<TextInput
							withAsterisk
							label="Naam"
							disabled={Object.keys(selected).length > 0}
							icon={<IconBriefcase size={14} />}
							{...form.getInputProps("naam")}
						/>
						<TextInput
							withAsterisk
							icon={<IconAt size={14} />}
							label="E-mail"
							disabled={Object.keys(selected).length > 0}
							{...form.getInputProps("email")}
						/>
						<TextInput
							withAsterisk
							label="Telefoon"
							icon={<IconPhone size={14} />}
							value={form.values.tel}
							error={form.errors.tel}
							onChange={handlePhoneChange}
							//	{...form.getInputProps("tel")}
						/>
						<TextInput
							withAsterisk
							label="BTW-nummer"
							icon={<IconNumber size={14} />}
							value={form.values.btw}
							error={form.errors.btw}
							maxLength={12}
							onChange={handleVATChange}
							//{...form.getInputProps("btw")}
						/>
						{gmapsLoaded && (
							<PlacesAutocomplete
								value={address}
								debounce={600}
								highlightFirstSuggestion
								searchOptions={{
									componentRestrictions: {
										country: ["BE", "NL"],
									},
								}}
								onSelect={(address) => {
									form.setValues({ facturatieAdres: address });
									geocodeByAddress(address)
										.then((results) => {
											const result = results[0];
											const address = result.address_components;
											const obj = {
												werfStraat: address.find((e) =>
													e.types.find((e) => e === "route")
												).long_name,
												werfNrBus: address.find((e) =>
													e.types.find((e) => e === "street_number")
												).long_name,
												werfPostcode: address.find((e) =>
													e.types.find((e) => e === "postal_code")
												).long_name,
												werfStad: address.find((e) =>
													e.types.find((e) => e === "locality")
												).long_name,
												werfLand: address.find((e) =>
													e.types.find((e) => e === "country")
												).long_name,
											};
											setWerfAdresObj(obj);
										})

										.catch((error) => console.error("Error", error));
								}}
								{...form.getInputProps("facturatieAdres")}
							>
								{({
									getInputProps,
									suggestions,
									getSuggestionItemProps,
									loading,
								}) => (
									<div>
										<TextInput
											{...getInputProps({
												placeholder: "Typ om te zoeken ...",
												className: "location-search-input",
											})}
											required
											error={
												form.isTouched("facturatieAdres") &&
												form.values.facturatieAdres.length < 1
											}
											label="Facturatieadres"
											icon={<IconMapPin size={14} />}
											rightSection={loading && <Loader size="xs" />}
										/>
										<Box>
											{suggestions.map((suggestion, i) => {
												const className = suggestion.active
													? "suggestion-item--active"
													: "suggestion-item";
												// inline style for demonstration purpose
												const style = suggestion.active
													? {
															backgroundColor: "#f0f4ff",
															cursor: "pointer",
													  }
													: {
															backgroundColor: "#ffffff",
															cursor: "pointer",
													  };
												return (
													<Box
														key={i}
														{...getSuggestionItemProps(suggestion, {
															className,
															style,
														})}
														sx={{
															borderLeft: "1px solid #CFD4D9",
															borderRight: "1px solid #CFD4D9",
															borderBottom:
																i === suggestions.length - 1 &&
																"1px solid #CFD4D9",
															borderBottomLeftRadius:
																i === suggestions.length - 1 && "5px",
															borderBottomRightRadius:
																i === suggestions.length - 1 && "5px",
															paddingTop: ".25rem",
															paddingBottom: ".25rem",
															paddingLeft: ".5rem",
															paddingRight: ".5rem",
														}}
													>
														<Text size="sm">{suggestion.description}</Text>
													</Box>
												);
											})}
										</Box>
									</div>
								)}
							</PlacesAutocomplete>
						)}
						<Text size="sm" weight={500}>
							Commissie
						</Text>
						<Slider
							mt={20}
							min={6}
							max={20}
							labelAlwaysOn
							step={2}
							label={(value) => `${value}%`}
							marks={[
								{ value: 6 },
								{ value: 8 },
								{ value: 10 },
								{ value: 12 },
								{ value: 20 },
							]}
							{...form.getInputProps("commissie")}
						/>

						<Group position="apart" mt={30}>
							{Object.keys(selected).length !== 0 &&
							selected.active === true ? (
								<Button
									onClick={() => handleDeleteCompany()}
									leftIcon={<IconTrash size={14} />}
									color="red"
								>
									Verwijderen
								</Button>
							) : (
								<Box />
							)}
							<Box>
								<Text size="xs" color="red">
									{error}
								</Text>
								{Object.keys(selected).length === 0 ? (
									<Button loading={loading} disabled={loading} type="submit">
										Aanmaken
									</Button>
								) : (
									<Button loading={loading} disabled={loading} type="submit">
										Bijwerken
									</Button>
								)}
							</Box>
						</Group>
					</Stack>
				</form>
				<Box sx={{ position: "absolute", bottom: ".5rem", right: ".5rem" }}>
					<Text size="xs" color="dimmed">
						id: {selected.id}
					</Text>
				</Box>
			</Drawer>
			<Container size="lg" py="lg">
				<Title sx={{ marginBottom: 30 }}>Bedrijven</Title>
				<Card shadow="xs" p="lg" radius="md">
					{companies.length > 0 ? (
						<DataGrid
							data={companies}
							highlightOnHover
							fontSize="14px"
							styles={(theme) => ({
								thead: {
									"::after": {
										backgroundColor: theme.colors.blue[5],
									},
								},
								pagination_info: {
									fontSize: "14px",
								},
							})}
							withColumnFilters
							withPagination
							withSorting
							striped
							initialState={{
								sorting: [
									{ id: "active", desc: true },
									{
										id: "naam",
										desc: true,
									},
								],
								pagination: {
									pageSize: 25,
								},
							}}
							locale={{
								globalSearch: "Zoeken...",
								pageSize: "Bedrijven per pagina:",
								pagination: (firstRowNum, lastRowNum, maxRows) => (
									<>
										Bedrijf <b>{firstRowNum}</b> tot <b>{lastRowNum}</b> van{" "}
										<b>{maxRows}</b> weergegeven
									</>
								),
							}}
							empty={<Title order={4}>Geen bedrijven gevonden</Title>}
							onRow={(row) => ({
								style: {
									cursor: "pointer",
									color: row.original.active === false && "lightgray",
								},
							})}
							onCell={(cell) =>
								cell.column.id === "naam"
									? {
											onClick: () => {
												navigate(
													`/app/dossiers?filter=aangemaaktDoorBedrijf&value=${cell.getValue()}`
												);
											},
									  }
									: {}
							}
							columns={[
								{
									accessorKey: "active",
									header: "",
									cell: (cell) => (
										<ColorSwatch
											size="8px"
											color={
												cell.getValue() === true
													? theme.colors.green[5]
													: theme.colors.red[5]
											}
										/>
									),
									size: 50,
									filterFn: booleanFilterFn,
								},
								{
									accessorKey: "naam",
									header: "Naam",
									size: 350,
									filterFn: stringFilterFn,

									cell: (cell) => (
										<Tooltip
											label="Toon alle dossiers"
											withArrow
											position="right"
											transition="scale-x"
											openDelay={150}
										>
											<Text weight={500}>{cell.getValue()}</Text>
										</Tooltip>
									),
								},

								{
									accessorKey: "email",
									header: "E-mail",
									filterFn: stringFilterFn,
									size: 200,
									cell: (cell) => (
										<Anchor
											href={`mailto:${cell.getValue()}`}
											sx={{
												color:
													cell.row.original.active === false && "lightgray",
											}}
										>
											{cell.getValue()}
										</Anchor>
									),
								},
								{
									accessorKey: "btw",
									header: "BTW",
									filterFn: stringFilterFn,
									size: 120,
								},
								{
									accessorKey: "commissie",
									header: "Commissie",
									cell: (cell) => <Text>{cell.getValue()}%</Text>,
									size: 90,
								},
								{
									accessorKey: null,
									header: " ",
									cell: (cell) => (
										<Tooltip label="Bewerken" withArrow>
											<ActionIcon
												variant="light"
												color="blue"
												radius="xl"
												onClick={() => setSelected(cell.row.original)}
											>
												<IconPencil size={16} />
											</ActionIcon>
										</Tooltip>
									),
									size: 30,
								},
							]}
						/>
					) : (
						<Text>
							<Link to="/">Maak een bedrijf aan</Link> om te beginnen
						</Text>
					)}
					<Group position="right" mt="xl">
						<Button
							onClick={() => setOpened(true)}
							leftIcon={<IconPlus size={14} />}
						>
							Nieuw bedrijf
						</Button>
					</Group>
				</Card>
			</Container>
		</>
	);
}
