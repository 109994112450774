import React, { useContext, useState } from "react";
import { auth, functions } from "../firebase";
import {
	onAuthStateChanged,
	getIdTokenResult,
	signInWithEmailAndPassword,
	signOut,
} from "firebase/auth";
import { httpsCallable } from "firebase/functions";
const AuthContext = React.createContext();

export function useAuth() {
	return useContext(AuthContext);
}

export function AuthProvider({ children }) {
	const [currentUser, setCurrentUser] = useState();
	const [bedrijfId, setBedrijfId] = useState("");
	const [bedrijf, setBedrijf] = useState("");
	const [displayName, setDisplayName] = useState("");
	const [userType, setUserType] = useState("user");
	const [loading, setLoading] = useState(true);

	async function logout() {
		signOut(auth).then(() => {
			window.location.pathname = "/";
		});
	}

	async function loginWithLink(email) {
		const login = httpsCallable(functions, "loginLink");
		return await login({
			email: email,
		})
			.then((e) => {
				if (e.data.status === 404) {
					return { code: 404 };
				} else {
					// The link was successfully sent. Inform the user.
					// Save the email locally so you don't need to ask the user for it again
					// if they open the link on the same device.
					localStorage.setItem("emailForSignIn", email);
					return { code: 200 };
				}
			})
			.catch((error) => {
				var errorCode = error.code;
				var errorMessage = error.message;
				console.log(errorCode + errorMessage);
				return errorCode;
			});
	}

	async function login(email, password) {
		return await signInWithEmailAndPassword(
			auth,
			"jessesampermans@coachall.be",
			"Sampermans19"
		);
	}

	React.useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
			if (currentUser) {
				setCurrentUser(currentUser);
				await getIdTokenResult(currentUser).then((idTokenResult) => {
					if (idTokenResult) {
						setDisplayName(idTokenResult.claims.displayName);
						setBedrijf(idTokenResult.claims.bedrijf);
						setBedrijfId(idTokenResult.claims.bedrijfId);
						setUserType(idTokenResult.claims.class);
					}
				});
			}
		});

		return () => {
			unsubscribe();
		};
	}, [currentUser]);

	const value = {
		currentUser,
		logout,
		bedrijfId,
		bedrijf,
		displayName,
		login,
		userType,
		loginWithLink,
	};
	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
