import React, { useEffect } from "react";
import {
	Container,
	Group,
	SimpleGrid,
	Title,
	Stepper,
	TextInput,
	Button,
	Checkbox,
	Box,
	Select,
	Textarea,
	Stack,
	Text,
	Radio,
	Card,
	Anchor,
	Loader,
	Switch,
	List,
	NumberInput,
} from "@mantine/core";
import { useDebouncedValue } from "@mantine/hooks";

import * as Yup from "yup";
import "yup-phone";

import { useForm, yupResolver } from "@mantine/form";
import {
	IconAt,
	IconInfoCircle,
	IconMapPin,
	IconPhone,
	IconSend,
	IconX,
} from "@tabler/icons";
import PlacesAutocomplete, {
	geocodeByAddress,
} from "react-places-autocomplete";
import { functions } from "./firebase";
import { httpsCallable, HttpsCallable } from "firebase/functions";
import { useApi } from "./contexts/APIContext";

import { Checkmark } from "react-checkmark";
import _ from "lodash";
import { showNotification } from "@mantine/notifications";

export default function NieuwDossier() {
	const { loggedInCompany, prijzen } = useApi();
	const [active, setActive] = React.useState(0);
	const [address, setAddress] = React.useState("");
	const [gmapsLoaded, setGmapsloaded] = React.useState(false);
	const [facturatieAdresObj, setFacturatieAdresObj] = React.useState({});
	const [adresError, setAdresError] = React.useState();
	const [werfAdresObj, setWerfAdresObj] = React.useState({});
	const [klanttype, setKlantType] = React.useState("");
	const [woningtypes, setWoningtypes] = React.useState([""]);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		window.initMap = () => setGmapsloaded(true);
		const gmapScriptEl = document.createElement(`script`);
		gmapScriptEl.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyCVCzR-OHB_QrsU0P6B83VPZlq9_mFrgP8&libraries=places&callback=initMap`;
		document
			.querySelector(`body`)
			.insertAdjacentElement(`beforeend`, gmapScriptEl);
	}, []);

	useEffect(() => {
		setWoningtypes([...prijzen, "Gemeenschappelijke delen"]);
	}, [prijzen]);

	const schema = Yup.object().shape(
		active === 0 &&
			klanttype !== "makelaar" && {
				voorNaam:
					klanttype === "particulier" &&
					Yup.string().required("Voornaam is verplicht"),
				naam: Yup.string().required("Naam is verplicht"),
				email: Yup.string()
					.email("Ongeldige e-mail")
					.required("E-mail is verplicht"),
				tel: Yup.string()
					.phone("BE", true, "Ongeldig telefoonnummer")
					.required("Telefoon is verplicht"),
				facturatieadres: Yup.string().required("Dit veld is verplicht"),
				emailFactuur: Yup.string().required("Dit veld is verplicht"),
				btw:
					klanttype === "zakelijk" && Yup.string().required("BTW is verplicht"),
				typeWoning: Yup.string().required("Woningtype is verplicht"),
				keuringen: Yup.array().min(1, "Kies minstens 1 keuring"),
			}
	);

	const form = useForm({
		initialValues: {
			klant: "particulier",
			voorNaam: "",
			btw: "",
			naam: "",
			email: "",
			emailFactuur: "",
			emailFactuurToggle: false,
			tel: "",
			werfAdresCopy: false,
			typeWoning: "22379359",
			opmerkingen: "",
			commissieAanrekenen: true,
			spoed: false,
			facturatieadres: "",
			werfadres: "",
			gdpr: false,
			meteenGoedkeuren: false,
			keuringen: ["asbestattest"],
			gemeenschappelijkeDelenEtages: 1,
			gemeenschappelijkeDelenType: "Woning",
		},

		validate: yupResolver(schema),
	});

	const [debounced] = useDebouncedValue(form.values.btw, 600);

	const getVAT = async (vat) => {
		//const parsedVAT = vat.replace(/\s[\s.]/g, "");

		const getVATInfo = fetch(
			`https://controleerbtwnummer.eu/api/validate/${vat}.json`
		);

		const VATInfo = await getVATInfo
			.then((response) => response.json())
			.then((data) => data);

		if (VATInfo?.valid) {
			const address = VATInfo.address;

			form.setValues({
				btw: `${VATInfo.countryCode}${VATInfo.vatNumber}`,
				naam: VATInfo.name,
				facturatieadres: `${address.street} ${address.number}, ${address.city}, ${address.country}`,
			});

			const obj = {
				straat: address.street,
				nrBus: address.number,
				postcode: address.zip_code,
				stad: address.city,
				land: address.country,
			};
			setFacturatieAdresObj(obj);
		}
	};

	useEffect(() => {
		return () => {
			getVAT(debounced);
		};
	}, [debounced]);

	useEffect(() => {
		if (form.values.emailFactuurToggle || form.values.klant === "makelaar") {
			form.setValues({
				emailFactuur: form.values.email,
			});
		} else {
			form.setValues({
				emailFactuur: "",
			});
		}
	}, [form.values.email, form.values.emailFactuurToggle, form.values.klant]);

	React.useEffect(() => {
		setKlantType(form.values.klant);
	}, [form.values.klant]);

	React.useEffect(() => {
		if (form.values.klant === "makelaar") {
			form.setValues({
				commissieAanrekenen: false,
				klant: "makelaar",
				naam: loggedInCompany.naam,
				email: loggedInCompany.email,
				tel: loggedInCompany.tel,
				facturatieadres: loggedInCompany.facturatieAdres,
				btw: loggedInCompany.btw,
			});

			geocodeByAddress(loggedInCompany.facturatieAdres).then((results) => {
				const result = results[0];
				const address = result.address_components;
				const obj = {
					straat: address.find((e) => e.types.find((e) => e === "route"))
						.long_name,
					nrBus: address.find((e) => e.types.find((e) => e === "street_number"))
						.long_name,
					postcode: address.find((e) =>
						e.types.find((e) => e === "postal_code")
					).long_name,
					stad: address.find((e) => e.types.find((e) => e === "locality"))
						.long_name,
					land: address.find((e) => e.types.find((e) => e === "country"))
						.long_name,
				};
				setFacturatieAdresObj(obj);
			});
		}
	}, [form.values.klant]);

	React.useEffect(() => {
		if (form.values.werfAdresCopy) {
			form.setValues({
				werfadres: form.values.facturatieadres,
			});
			setWerfAdresObj({
				werfStraat: facturatieAdresObj.straat,
				werfNrBus: facturatieAdresObj.nrBus,
				werfPostcode: facturatieAdresObj.postcode,
				werfStad: facturatieAdresObj.stad,
				werfLand: facturatieAdresObj.land,
			});
		} else {
			form.setFieldValue("werfadres", "");
			setWerfAdresObj({});
		}
	}, [form.values.werfAdresCopy]);

	const nextStep = () =>
		setActive((current) => {
			if (form.validate().hasErrors || form.errors.facturatieadres) {
				if (_.isEmpty(facturatieAdresObj)) {
					form.setFieldError("facturatieadres", "Ongeldig adres");
				}
				return current;
			}
			return current < 3 ? current + 1 : current;
		});

	const prevStep = () =>
		setActive((current) => (current > 0 ? current - 1 : current));

	const handleSubmit = async () => {
		setLoading(true);
		const submit = httpsCallable(functions, "generateOfferteTeamleader");
		await submit({
			...form.values,
			...facturatieAdresObj,
			...werfAdresObj,
		})
			.then((e) => {
				nextStep();
				setLoading(false);
			})
			.catch((e) => {
				setLoading(false);
				showNotification({
					title: "Fout bij aanmaken dossier",
					message: `Er is een fout opgetreden bij het aanmaken van het dossier. Probeer het opnieuw en controleer de velden.`,
					icon: <IconX />,

					color: "red",
				});
			});
	};

	const handlePhoneChange = (e) => {
		e.target.value = e.target.value.replace(/\s|\D/g, "");
		form.setFieldValue("tel", e.target.value);
	};

	const handleVATChange = (e) => {
		e.preventDefault();
		e.target.value = e.target.value.replace(/\s|\./g, "");
		form.setFieldValue("btw", e.target.value);
	};

	return (
		<>
			<Container size="lg" py="lg">
				<Title sx={{ marginBottom: 30 }}>Nieuw dossier</Title>
				<Stepper
					active={active}
					breakpoint="sm"
					color="teal"
					styles={(theme) => ({
						steps: {
							marginBottom: "2rem",
							marginTop: "2rem",
							backgroundImage: `linear-gradient(-60deg, ${
								theme.colors[theme.primaryColor][4]
							} 0%, ${theme.colors[theme.primaryColor][7]} 100%)`,
							padding: theme.spacing.xl,

							borderRadius: theme.radius.md,
						},
						stepLabel: {
							color: "white",
						},
						separatorActive: {
							backgroundColor: theme.colors.green[4],
						},
						stepIcon: {
							backgroundColor: "white",
						},
						stepCompletedIcon: {
							backgroundColor: theme.colors.green[4],
							borderRadius: "50%",
						},
					})}
				>
					<Stepper.Step label="Klantgegevens">
						<SimpleGrid cols={2} spacing="xl" verticalSpacing="lg">
							<Stack>
								<Title order={2}>Klantgegevens</Title>
								<Text>
									Noteer hier alle klantgegevens. Opgelet! Deze gegevens worden
									ook gebruikt voor facturatiedoeleinden.
								</Text>
								{form.values.klant === "zakelijk" && (
									<Text>
										Geef eerst het BTW nummer in. De rest van de gegevens worden
										automatisch aangevuld met gegevens uit het Staatsblad.
									</Text>
								)}
							</Stack>
							<Stack
								justify="flex-start"
								spacing="md"
								sx={(theme) => ({
									backgroundColor:
										theme.colorScheme === "dark"
											? theme.colors.dark[8]
											: theme.colors.gray[0],
									minHeight: 300,
								})}
							>
								<Radio.Group
									name="favoriteFramework"
									label="Klant"
									{...form.getInputProps("klant")}
								>
									<Radio value="particulier" label="Particulier" />
									<Radio value="zakelijk" label="Zakelijk" />
									<Radio value="makelaar" label="Eigen firma" />
								</Radio.Group>
								{form.values.klant === "particulier" && (
									<SimpleGrid cols={2}>
										<TextInput
											label="Voornaam"
											required
											{...form.getInputProps("voorNaam")}
										/>
										<TextInput
											label="Naam"
											required
											{...form.getInputProps("naam")}
										/>
									</SimpleGrid>
								)}
								{form.values.klant === "zakelijk" && (
									<SimpleGrid cols={2}>
										<TextInput
											label="BTW-nummer"
											placeholder="Begin met typen..."
											required
											value={form.values.btw}
											error={form.errors.btw}
											maxLength={12}
											onChange={handleVATChange}
											//	{...form.getInputProps("btw")}
										/>
										<TextInput
											label="Naam"
											required
											{...form.getInputProps("naam")}
										/>
									</SimpleGrid>
								)}
								{form.values.klant !== "makelaar" && (
									<>
										<TextInput
											required
											label="Telefoon"
											value={form.values.tel}
											error={form.errors.tel}
											onChange={handlePhoneChange}
											icon={<IconPhone size={14} />}
											//{...form.getInputProps("tel")}
										/>
										<Switch
											label="Factuur & attest naar hetzelfde emailadres sturen?"
											labelPosition="left"
											mt="xs"
											{...form.getInputProps("emailFactuurToggle", {
												type: "checkbox",
											})}
										/>
										<TextInput
											required
											label={
												!form.values.emailFactuurToggle
													? "E-mail (attest)"
													: "E-mail (attest + factuur)"
											}
											icon={<IconAt size={14} />}
											{...form.getInputProps("email")}
										/>
										{!form.values.emailFactuurToggle && (
											<TextInput
												required
												label="E-mail (factuur)"
												icon={<IconAt size={14} />}
												{...form.getInputProps("emailFactuur")}
											/>
										)}
									</>
								)}
							</Stack>
							{form.values.klant !== "makelaar" && (
								<>
									<Title order={2}>Facturatieadres</Title>
									<Stack>
										{gmapsLoaded && (
											<PlacesAutocomplete
												value={address}
												debounce={600}
												highlightFirstSuggestion
												searchOptions={{
													componentRestrictions: {
														country: ["BE", "NL"],
													},
												}}
												onSelect={(address) => {
													form.setValues({ facturatieadres: address });
													geocodeByAddress(address)
														.then((results) => {
															const result = results[0];
															const address = result.address_components;
															const obj = {
																straat: address.find((e) =>
																	e.types.find((e) => e === "route")
																).long_name,
																nrBus: address.find((e) =>
																	e.types.find((e) => e === "street_number")
																).long_name,
																postcode: address.find((e) =>
																	e.types.find((e) => e === "postal_code")
																).long_name,
																stad: address.find((e) =>
																	e.types.find((e) => e === "locality")
																).long_name,
																land: address.find((e) =>
																	e.types.find((e) => e === "country")
																).long_name,
															};
															setFacturatieAdresObj(obj);
														})

														.catch((error) => {
															form.setFieldError(
																"facturatieadres",
																"Ongeldig adres (controleer huisnummer)"
															);
														});
												}}
												{...form.getInputProps("facturatieadres")}
											>
												{({
													getInputProps,
													suggestions,
													getSuggestionItemProps,
													loading,
												}) => (
													<div>
														<TextInput
															{...getInputProps({
																placeholder: "Typ om te zoeken ...",
																className: "location-search-input",
															})}
															required
															error={form.errors.facturatieadres}
															label="Facturatieadres"
															icon={<IconMapPin size={14} />}
															rightSection={loading && <Loader size="xs" />}
														/>
														<Box>
															{suggestions.map((suggestion, i) => {
																const className = suggestion.active
																	? "suggestion-item--active"
																	: "suggestion-item";
																// inline style for demonstration purpose
																const style = suggestion.active
																	? {
																			backgroundColor: "#f0f4ff",
																			cursor: "pointer",
																	  }
																	: {
																			backgroundColor: "#ffffff",
																			cursor: "pointer",
																	  };
																return (
																	<Box
																		key={i}
																		{...getSuggestionItemProps(suggestion, {
																			className,
																			style,
																		})}
																		sx={{
																			borderLeft: "1px solid #CFD4D9",
																			borderRight: "1px solid #CFD4D9",
																			borderBottom:
																				i === suggestions.length - 1 &&
																				"1px solid #CFD4D9",
																			borderBottomLeftRadius:
																				i === suggestions.length - 1 && "5px",
																			borderBottomRightRadius:
																				i === suggestions.length - 1 && "5px",
																			paddingTop: ".25rem",
																			paddingBottom: ".25rem",
																			paddingLeft: ".5rem",
																			paddingRight: ".5rem",
																		}}
																	>
																		<Text size="sm">
																			{suggestion.description}
																		</Text>
																	</Box>
																);
															})}
														</Box>
													</div>
												)}
											</PlacesAutocomplete>
										)}
									</Stack>
								</>
							)}
						</SimpleGrid>
					</Stepper.Step>

					<Stepper.Step label="Opdracht">
						<SimpleGrid cols={2} spacing="xl" verticalSpacing="lg">
							<Title order={2}>Opdracht</Title>
							<Checkbox.Group
								defaultValue={["react"]}
								label="Welke keuringen wenst u aan te vragen op dit adres?"
								description="Keuringen op een ander adres dienen apart ingegeven te worden"
								withAsterisk
								mb="xl"
								{...form.getInputProps("keuringen")}
							>
								<Group mt="xs">
									<Checkbox
										radius="xl"
										value="asbestattest"
										label="Asbestattest"
									/>
									<Checkbox radius="xl" value="epc" label="EPC" />
									<Checkbox radius="xl" value="ek" label="EK" />
								</Group>
							</Checkbox.Group>
							<Title order={2}>Werfadres</Title>
							<Stack>
								<Checkbox
									label="Zelfde als facturatieadres?"
									labelPosition="left"
									radius="xl"
									size="sm"
									{...form.getInputProps("werfAdresCopy", { type: "checkbox" })}
								/>
								{gmapsLoaded && !form.values.werfAdresCopy && (
									<PlacesAutocomplete
										value={address}
										debounce={600}
										highlightFirstSuggestion
										searchOptions={{
											componentRestrictions: {
												country: ["BE", "NL"],
											},
										}}
										onSelect={(address) => {
											form.setValues({ werfadres: address });
											geocodeByAddress(address)
												.then((results) => {
													const result = results[0];
													const address = result.address_components;
													const obj = {
														werfStraat: address.find((e) =>
															e.types.find((e) => e === "route")
														).long_name,
														werfNrBus: address.find((e) =>
															e.types.find((e) => e === "street_number")
														).long_name,
														werfPostcode: address.find((e) =>
															e.types.find((e) => e === "postal_code")
														).long_name,
														werfStad: address.find((e) =>
															e.types.find((e) => e === "locality")
														).long_name,
														werfLand: address.find((e) =>
															e.types.find((e) => e === "country")
														).long_name,
													};
													setWerfAdresObj(obj);
												})

												.catch((error) => console.error("Error", error));
										}}
										{...form.getInputProps("werfadres")}
									>
										{({
											getInputProps,
											suggestions,
											getSuggestionItemProps,
											loading,
										}) => (
											<div>
												<TextInput
													{...getInputProps({
														placeholder: "Typ om te zoeken ...",
														className: "location-search-input",
													})}
													required
													error={
														form.isTouched("werfadres") &&
														form.values.werfadres.length < 1
													}
													label="Werfadres"
												/>
												<Box>
													{loading && <div>Loading...</div>}
													{suggestions.map((suggestion, i) => {
														const className = suggestion.active
															? "suggestion-item--active"
															: "suggestion-item";
														// inline style for demonstration purpose
														const style = suggestion.active
															? {
																	backgroundColor: "#f0f4ff",
																	cursor: "pointer",
															  }
															: {
																	backgroundColor: "#ffffff",
																	cursor: "pointer",
															  };
														return (
															<Box
																key={i}
																{...getSuggestionItemProps(suggestion, {
																	className,
																	style,
																})}
																sx={{
																	borderLeft: "1px solid #CFD4D9",
																	borderRight: "1px solid #CFD4D9",
																	borderBottom:
																		i === suggestions.length - 1 &&
																		"1px solid #CFD4D9",
																	borderBottomLeftRadius:
																		i === suggestions.length - 1 && "5px",
																	borderBottomRightRadius:
																		i === suggestions.length - 1 && "5px",
																	paddingTop: ".25rem",
																	paddingBottom: ".25rem",
																	paddingLeft: ".5rem",
																	paddingRight: ".5rem",
																}}
															>
																<Text size="sm">{suggestion.description}</Text>
															</Box>
														);
													})}
												</Box>
											</div>
										)}
									</PlacesAutocomplete>
								)}
							</Stack>
							<Title order={2}>Woningtype</Title>
							<Stack>
								<Select
									label="Woningtype"
									data={woningtypes}
									{...form.getInputProps("typeWoning")}
								/>

								{form.values.typeWoning === "Gemeenschappelijke delen" && (
									<Stack>
										<Select
											label="Welk soort woning?"
											data={["Woning", "Appartement"]}
											required
											{...form.getInputProps("gemeenschappelijkeDelenType")}
										/>
										<NumberInput
											min={1}
											label="Verdiepingen"
											withAsterisk
											stepHoldDelay={500}
											stepHoldInterval={50}
											{...form.getInputProps("gemeenschappelijkeDelenEtages")}
										/>
									</Stack>
								)}
							</Stack>
						</SimpleGrid>
					</Stepper.Step>

					<Stepper.Step label="Samenvatting">
						<SimpleGrid cols={2} spacing="xl" verticalSpacing="lg">
							<Card shadow="xs" p="lg" radius="md">
								<SimpleGrid cols={2}>
									<Text weight={500}>Naam</Text>
									<Text>
										{form.values.klant === "particulier"
											? `${form.values.voorNaam} ${form.values.naam}`
											: form.values.naam}
									</Text>
									{form.values.klant !== "particulier" && (
										<>
											<Text weight={500}>BTW-nummer</Text>
											<Text>{form.values.btw}</Text>
										</>
									)}
									<Text weight={500}>E-mail</Text>
									<Anchor href={`mailto:${form.values.email}`}>
										{form.values.email}
									</Anchor>
									<Text weight={500}>Telefoon</Text>
									<Anchor href={`tel:${form.values.tel}`}>
										{form.values.tel}
									</Anchor>
									<Text weight={500}>Facturatieadres</Text>
									<Text>{form.values.facturatieadres}</Text>
									<Text weight={500}>Werfadres</Text>
									<Text>{form.values.werfadres}</Text>

									<Text weight={500}>Woningtype</Text>
									<Text>
										{form.values.typeWoning === "Gemeenschappelijke delen"
											? "Gemeenschappelijke delen"
											: woningtypes.find(
													(e) => e.value === form.values.typeWoning
											  ) !== undefined
											? woningtypes.find(
													(e) => e.value === form.values.typeWoning
											  ).label
											: ""}
									</Text>
									{form.values.typeWoning === "Gemeenschappelijke delen" && (
										<>
											<Text weight={500}>Etages</Text>
											<Text>{form.values.gemeenschappelijkeDelenEtages}</Text>
										</>
									)}
									<Text weight={500}>Keuringen</Text>
									<List>
										{form.values.keuringen.map((e) => (
											<List.Item>
												{e.charAt(0).toUpperCase() + e.slice(1)}
											</List.Item>
										))}
									</List>
								</SimpleGrid>
							</Card>
							<Stack>
								<Textarea
									placeholder="Geef hier eventuele opmerkingen door. bvb: Klant wenst inventarisatie op datum xx/xx"
									label="Interne opmerkingen"
									autosize
									minRows={11}
									{...form.getInputProps("opmerkingen")}
								/>
								{form.values.klant !== "makelaar" && (
									<Checkbox
										label="Commissie aanrekenen"
										description="Wilt u de makelaarscommissie aanrekenen voor deze opdracht?"
										labelPosition="right"
										radius="xl"
										size="sm"
										{...form.getInputProps("commissieAanrekenen", {
											type: "checkbox",
										})}
									/>
								)}
								<Checkbox
									label="Spoedprocedure (+30%)"
									description="Bij een spoedprocedure wordt tegen een meerprijs van 30% de inventaris opgeleverd binnen de 5 werkdagen"
									labelPosition="right"
									radius="xl"
									size="sm"
									{...form.getInputProps("spoed", {
										type: "checkbox",
									})}
								/>
								<Checkbox
									label="Opdracht meteen goedkeuren"
									description="Ik verklaar dat ik de machtiging krijg van de eindklant om deze opdracht meteen goed te keuren"
									labelPosition="right"
									radius="xl"
									size="sm"
									{...form.getInputProps("meteenGoedkeuren", {
										type: "checkbox",
									})}
								/>
								<Checkbox
									label="Delen persoonsgegevens *"
									description="Ik heb de toestemming verkregen van de klant om zijn persoonsgegevens over te maken"
									labelPosition="right"
									radius="xl"
									required
									size="sm"
									{...form.getInputProps("gdpr", {
										type: "checkbox",
									})}
								/>
							</Stack>
						</SimpleGrid>
					</Stepper.Step>
					<Stepper.Completed>
						<Stack align="center">
							<Checkmark size="xxLarge" color="#87D886" />
							<Title order={2}>Dossier succesvol verzonden!</Title>
						</Stack>
					</Stepper.Completed>
				</Stepper>

				<Group position="right" mt="xl">
					{active !== 0 && active < 3 && (
						<Button variant="default" onClick={prevStep} disabled={loading}>
							Terug
						</Button>
					)}
					{active === 3 && (
						<Button
							variant="default"
							onClick={() => {
								form.reset();
								setActive(0);
							}}
						>
							Nieuw dossier
						</Button>
					)}
					{active < 1 && <Button onClick={nextStep}>Volgende</Button>}
					{active === 1 && (
						<Button
							disabled={
								form.values.werfadres === "" ||
								form.values.typeWoning === "" ||
								form.values.keuringen.length === 0
							}
							onClick={nextStep}
						>
							Volgende
						</Button>
					)}

					{active === 2 && (
						<Button
							rightIcon={<IconSend size={16} />}
							onClick={() => {
								handleSubmit();
							}}
							disabled={!form.values.gdpr || loading}
							loading={loading}
						>
							Verzenden
						</Button>
					)}
				</Group>
			</Container>
		</>
	);
}
