import React from "react";
import { useApi } from "./contexts/APIContext";
import {
	DataGrid,
	highlightFilterValue,
	createDateFilter,
	stringFilterFn,
} from "mantine-data-grid";
import {
	MultiSelect,
	Container,
	Title,
	Text,
	Card,
	ActionIcon,
	Tooltip,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { IconFileText } from "@tabler/icons";
import { ref, getDownloadURL } from "firebase/storage";
import { storage } from "./firebase";
import { useAuth } from "./contexts/AuthContext";
import { saveAs } from "file-saver";

const dateFilter = createDateFilter({
	title: "Datum",
	placeholder: "Selecteer datum",
	labels: {
		bet: "Tussen",
		beteq: "Van tot",
		nbet: "Niet tussen",
		eq: "Gelijk aan",
		gt: "Later dan",
		gte: "Later dan of gelijk",
		lt: "Kleiner als",
		lte: "Kleiner of gelijk",
		neq: "Is niet",
	},
});

export default function Bestelbonnen() {
	const { bestelbonnen } = useApi();
	const { bedrijfId } = useAuth();

	return (
		<Container size="lg" py="lg">
			<Title sx={{ marginBottom: 30 }}>Bestelbonnen</Title>
			<Card shadow="xs" p="lg" radius="md">
				{bestelbonnen.length > 0 ? (
					<DataGrid
						data={bestelbonnen}
						styles={(theme) => ({
							thead: {
								"::after": {
									backgroundColor: theme.colors.blue[5],
								},
							},
							pagination_info: {
								fontSize: "14px",
							},
						})}
						highlightOnHover
						withPagination
						withColumnFilters
						withSorting
						initialState={{
							sorting: [
								{
									id: "datumRaw",
									desc: true,
								},
							],
						}}
						locale={{
							globalSearch: "Zoeken...",
							pageSize: "Bestelbonnen per pagina:",
							pagination: (firstRowNum, lastRowNum, maxRows) => (
								<>
									Bestelbon <b>{firstRowNum}</b> tot <b>{lastRowNum}</b> van{" "}
									<b>{maxRows}</b> weergegeven
								</>
							),
						}}
						empty={<Title order={4}>Geen bestelbonnen gevonden</Title>}
						columns={[
							{
								accessorKey: "datumRaw",
								header: "Datum",
								filterFn: dateFilter,
								size: "50%",
								cell: (cell) =>
									dayjs(cell.getValue())
										.locale("nl-be")
										.format("DD MMM YYYY HH:mm"),
							},
							{
								accessorKey: "nummer",
								header: "Nummer",
								size: "20%",
								cell: highlightFilterValue,
								filterFn: stringFilterFn,
							},
							{
								accessorKey: "bedrag",
								header: "Bedrag ex. BTW",
								filterFn: stringFilterFn,
								size: "30%",
								cell: (cell) => <Text>{cell.getValue()} EUR</Text>,
							},
							{
								accessorKey: null,
								header: " ",
								size: 10,
								cell: (cell) => (
									<Tooltip label="Bekijken" withArrow>
										<ActionIcon
											size="sm"
											onClick={() => {
												const fileRef = ref(
													storage,
													`companies/${bedrijfId}/bestelbonnen/${cell.row.original.id}.pdf`
												);
												getDownloadURL(fileRef).then((url) => {
													saveAs(url, `${cell.row.original.nummer}.pdf`);
												});
											}}
										>
											<IconFileText />
										</ActionIcon>
									</Tooltip>
								),
							},
						]}
					/>
				) : (
					<Text>Nog geen bestelbonnen gegenereerd</Text>
				)}
			</Card>
		</Container>
	);
}
