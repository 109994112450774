import React, { useContext, useState } from "react";
import { auth, db } from "../firebase";
import {
	collection,
	onSnapshot,
	query,
	collectionGroup,
	doc,
	where,
} from "firebase/firestore";
import { useAuth } from "./AuthContext";
import("dayjs/locale/nl-be");

const APIContext = React.createContext();

export function useApi() {
	return useContext(APIContext);
}

export function APIProvider({ children }) {
	const { bedrijfId, currentUser, userType } = useAuth();

	const [users, setUsers] = React.useState([]);
	const [userlist, setUserlist] = React.useState([]);
	const [deals, setDeals] = React.useState([]);
	const [bestelbonnen, setBestelbonnen] = React.useState([]);
	const [alleBestelbonnen, setAlleBestelbonnen] = React.useState([]);
	const [myDeals, setMyDeals] = React.useState([]);
	const [allDeals, setAllDeals] = React.useState([]);
	const [allUsers, setAllUsers] = React.useState([]);
	const [companies, setCompanies] = React.useState([]);
	const [companyList, setCompanyList] = React.useState([]);
	const [prijzen, setPrijzen] = React.useState([]);
	const [loggedInCompany, setLoggedInCompany] = React.useState({});

	const [dossierFilterUser, setDossierFilterUser] = React.useState("");
	const [dossierFilterApp, setDossierFilterApp] = React.useState("");

	React.useLayoutEffect(() => {
		if (auth.currentUser !== null && bedrijfId !== undefined) {
			const unsub = onSnapshot(
				collection(db, "companies", bedrijfId, "deals"),
				(querySnapshot) => {
					const arr = [];
					const myDealsArr = [];
					querySnapshot.forEach((doc) => {
						if (doc.data().makerUserId === currentUser.uid) {
							myDealsArr.push({
								...doc.data(),
								id: doc.id,
								datumRaw: doc.data().offerteAanmaakDatum.toDate(),
							});
						}
						arr.push({
							...doc.data(),
							id: doc.id,
							datumRaw: doc.data().offerteAanmaakDatum.toDate(),
						});
					});
					setDeals(arr);
					setMyDeals(myDealsArr);
				}
			);
			const unsubPrijzen = onSnapshot(
				collection(db, "general", "prijzen", "teamleader"),
				(querySnapshot) => {
					const arr = [];
					querySnapshot.forEach((doc) => {
						if (doc.data().category === "Asbestinventaris")
							arr.push({ label: doc.data().name, value: doc.data().id });
					});

					setPrijzen(arr);
				}
			);
			const unsubBestelbonnen = onSnapshot(
				collection(db, "companies", bedrijfId, "bestelbonnen"),
				(querySnapshot) => {
					const arr = [];
					querySnapshot.forEach((doc) => {
						arr.push({
							...doc.data(),
							id: doc.id,
							aanmaakDatum: doc.data().datum.toDate(),
						});
					});
					setBestelbonnen(arr);
				}
			);

			const unsubUsers = onSnapshot(
				collection(db, "companies", bedrijfId, "users"),
				(querySnapshot) => {
					const arr = [];
					querySnapshot.forEach((doc) => {
						arr.push({
							...doc.data(),
							id: doc.id,
						});
					});
					setUsers(arr);
				}
			);

			const unsubLoggedInCompany = onSnapshot(
				doc(db, "companies", bedrijfId),
				(doc) => {
					setLoggedInCompany(doc.data());
				}
			);

			if (userType === "superuser") {
				const unsubDeals = onSnapshot(
					collectionGroup(db, "deals"),
					(querySnapshot) => {
						const arr = [];
						querySnapshot.forEach((doc) => {
							arr.push({
								...doc.data(),
								id: doc.id,
								datumRaw: doc.data().offerteAanmaakDatum.toDate(),
							});
						});
						setAllDeals(arr);
					}
				);

				const unsubCompanies = onSnapshot(
					collection(db, "companies"),
					(querySnapshot) => {
						const arr = [];
						querySnapshot.forEach((doc) => {
							arr.push({
								...doc.data(),
								id: doc.id,
							});
						});
						setCompanies(arr);
					}
				);

				const unsubUsers = onSnapshot(
					collectionGroup(db, "users"),
					(querySnapshot) => {
						const arr = [];
						querySnapshot.forEach((doc) => {
							arr.push({
								...doc.data(),
								id: doc.id,
								companyId: doc.ref.parent.parent.id,
							});
						});
						setAllUsers(arr);
					}
				);

				const unsubAlleBestelbonnen = onSnapshot(
					collectionGroup(db, "bestelbonnen"),
					(querySnapshot) => {
						const arr = [];
						querySnapshot.forEach((doc) => {
							arr.push({
								...doc.data(),
								id: doc.id,
								aanmaakDatum: doc.data().datum.toDate(),
							});
						});
						setAlleBestelbonnen(arr);
					}
				);

				return () => {
					unsubDeals();
					unsubCompanies();
					unsubUsers();
					unsubAlleBestelbonnen();
				};
			}

			return () => {
				unsub();
				unsubUsers();
				unsubLoggedInCompany();
				unsubBestelbonnen();
				unsubPrijzen();
			};
		} else {
			setDeals([]);
		}
	}, [auth.currentUser, bedrijfId]);

	React.useEffect(() => {
		const list = users.map((e) => {
			return { value: e.naam, label: e.naam };
		});

		setUserlist(list);
		const comps = companies.map((e) => {
			return { value: e.naam, label: e.naam };
		});
		setCompanyList(comps);
	}, [users, companies]);

	const value = {
		deals,
		myDeals,
		allDeals,
		allUsers,
		users,
		userlist,
		setDossierFilterUser,
		dossierFilterUser,
		setDossierFilterApp,
		dossierFilterApp,
		companies,
		companyList,
		loggedInCompany,
		bestelbonnen,
		alleBestelbonnen,
		prijzen,
	};
	return <APIContext.Provider value={value}>{children}</APIContext.Provider>;
}
