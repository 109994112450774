import React, { useState } from "react";
import {
	Navbar,
	SegmentedControl,
	Image,
	createStyles,
	Center,
	Group,
	Text,
	Stack,
	ActionIcon,
	Tooltip,
	Avatar,
} from "@mantine/core";
import {
	IconUsers,
	IconLogout,
	IconPencil,
	IconFolders,
	IconBriefcase,
	IconSettings,
	IconReceiptTax,
} from "@tabler/icons";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import logo from "../img/logo.png";
import PersonalOverview from "./PersonalOverview";
import CompanyOverview from "./CompanyOverview";
const useStyles = createStyles((theme, _params, getRef) => {
	const icon = getRef("icon");

	return {
		navbar: {
			backgroundColor:
				theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
		},

		title: {
			textTransform: "uppercase",
			letterSpacing: -0.25,
		},

		link: {
			...theme.fn.focusStyles(),
			display: "flex",
			alignItems: "center",
			textDecoration: "none",
			fontSize: theme.fontSizes.sm,
			color:
				theme.colorScheme === "dark"
					? theme.colors.dark[1]
					: theme.colors.gray[7],
			padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
			borderRadius: theme.radius.sm,
			fontWeight: 500,

			"&:hover": {
				backgroundColor:
					theme.colorScheme === "dark"
						? theme.colors.dark[6]
						: theme.colors.gray[0],
				color: theme.colorScheme === "dark" ? theme.white : theme.black,

				[`& .${icon}`]: {
					color: theme.colorScheme === "dark" ? theme.white : theme.black,
				},
			},
		},

		linkIcon: {
			ref: icon,
			color:
				theme.colorScheme === "dark"
					? theme.colors.dark[2]
					: theme.colors.gray[6],
			marginRight: theme.spacing.sm,
		},

		linkActive: {
			"&, &:hover": {
				backgroundColor: theme.fn.variant({
					variant: "light",
					color: theme.primaryColor,
				}).background,
				color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
					.color,
				[`& .${icon}`]: {
					color: theme.fn.variant({
						variant: "light",
						color: theme.primaryColor,
					}).color,
				},
			},
		},

		footer: {
			borderTop: `1px solid ${
				theme.colorScheme === "dark"
					? theme.colors.dark[4]
					: theme.colors.gray[3]
			}`,
			paddingTop: theme.spacing.md,
			marginTop: 20,
		},
	};
});

const tabs = {
	account: [
		{ link: "/", label: "Nieuw dossier", icon: IconPencil },
		{ link: "dossiers", label: "Mijn dossiers", icon: IconFolders },
	],
	admin: [
		{
			link: "admin/bedrijfsdossiers",
			label: "Bedrijfsdossiers",
			icon: IconFolders,
		},
		{ link: "admin/gebruikers", label: "Gebruikers", icon: IconUsers },
		{ link: "admin/bestelbonnen", label: "Bestelbonnen", icon: IconReceiptTax },
	],
	app: [
		{
			link: "app/dossiers",
			label: "Alle dossiers",
			icon: IconFolders,
		},
		{ link: "app/bedrijven", label: "Bedrijven", icon: IconBriefcase },
		{ link: "app/users", label: "Alle gebruikers", icon: IconUsers },
		{
			link: "app/allebestelbonnen",
			label: "Alle bestelbonnen",
			icon: IconReceiptTax,
		},
		{ link: "app/settings", label: "Instellingen", icon: IconSettings },
	],
};

export function WorkspaceNavbar() {
	const { classes, cx } = useStyles();
	const [section, setSection] = useState("account");
	const [active, setActive] = useState("");
	const [currentLocation, setCurrentLocation] = useState("");
	const location = useLocation();
	const { logout, userType, currentUser, displayName, bedrijf } = useAuth();

	React.useEffect(() => {
		const locationArr = location.pathname.split("/");

		switch (locationArr[1]) {
			case "dossiers":
				setActive("Mijn dossiers");
				break;
			case "":
				setActive("Nieuw dossier");
				break;
			case "admin":
				setSection("admin");
				break;
			default:
				break;
		}

		if (locationArr.length > 2) {
			switch (locationArr[2]) {
				case "bedrijfsdossiers":
					setSection("admin");

					setActive("Bedrijfsdossiers");
					break;
				case "gebruikers":
					setSection("admin");

					setActive("Gebruikers");
					break;
				case "users":
					setSection("app");
					setActive("Alle gebruikers");
					break;
				case "bestelbonnen":
					setSection("admin");
					setActive("Bestelbonnen");
					break;
				case "allebestelbonnen":
					setSection("app");
					setActive("Alle bestelbonnen");
					break;
				case "dossiers":
					setSection("app");
					setActive("Alle dossiers");
					break;
				case "bedrijven":
					setSection("app");
					setActive("Bedrijven");
					break;
				case "settings":
					setSection("app");
					setActive("Instellingen");
					break;
				default:
					break;
			}
		}
	}, [location]);

	const links = tabs[section].map((item) => (
		<Link
			className={cx(classes.link, {
				[classes.linkActive]: item.label === active,
			})}
			to={item.link}
			key={item.label}
			onClick={(event) => {
				setActive(item.label);
			}}
		>
			<item.icon className={classes.linkIcon} stroke={1.5} />
			<span>{item.label}</span>
		</Link>
	));

	return (
		<Navbar height="100%" width={{ sm: 300 }} p="md" className={classes.navbar}>
			<Center>
				<Image width="70%" src={logo} alt="" withPlaceholder mb={30} ml={50} />
			</Center>
			{userType === "admin" && (
				<Navbar.Section>
					<SegmentedControl
						value={section}
						onChange={(value) => setSection(value)}
						transitionTimingFunction="ease"
						fullWidth
						data={[
							{ label: "Account", value: "account" },
							{
								label: "Beheer",
								value: "admin",
							},
						]}
					/>
				</Navbar.Section>
			)}
			{userType === "superuser" && (
				<Navbar.Section>
					<SegmentedControl
						value={section}
						onChange={(value) => setSection(value)}
						transitionTimingFunction="ease"
						fullWidth
						data={[
							{ label: "Gebruiker", value: "account" },

							{ label: "Hoofdgebruiker", value: "admin" },
							{ label: "A² ", value: "app" },
						]}
					/>
				</Navbar.Section>
			)}

			<Navbar.Section grow mt="xl">
				{links}
			</Navbar.Section>
			{section === "account" && (
				<Navbar.Section>
					<PersonalOverview />
				</Navbar.Section>
			)}
			{section === "admin" && (
				<Navbar.Section>
					<CompanyOverview />
				</Navbar.Section>
			)}

			<Navbar.Section className={classes.footer}>
				<Group position="apart">
					<Group>
						<Avatar radius="xl" color="blue" />
						<Stack spacing={0}>
							<Text size="sm">{displayName}</Text>
							<Text size="sm" color="dimmed">
								{bedrijf}
							</Text>
						</Stack>
					</Group>

					<Tooltip label="Afmelden" withArrow>
						<ActionIcon
							onClick={() => {
								logout();
							}}
						>
							<IconLogout className={classes.linkIcon} stroke={1.5} />
						</ActionIcon>
					</Tooltip>
				</Group>
			</Navbar.Section>
		</Navbar>
	);
}
