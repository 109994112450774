import React from "react";

import {
	Paper,
	createStyles,
	TextInput,
	PasswordInput,
	Group,
	Button,
	Title,
	Text,
	Image,
	Center,
	Stack,
	Box,
	Anchor,
} from "@mantine/core";
import { useAuth } from "./contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { isSignInWithEmailLink, signInWithEmailLink } from "firebase/auth";
import { auth } from "./firebase";
import { IconLock } from "@tabler/icons";
import logo from "./img/logo.png";
import { Checkmark } from "react-checkmark";
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";

const useStyles = createStyles((theme) => ({
	wrapper: {
		height: "100vh",
		backgroundSize: "cover",
		backgroundImage:
			"url(https://images.unsplash.com/photo-1484242857719-4b9144542727?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1280&q=80)",
	},

	form: {
		borderRight: `1px solid ${
			theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.colors.gray[3]
		}`,
		height: "100vh",
		maxWidth: 450,
		paddingTop: 80,

		[`@media (max-width: ${theme.breakpoints.sm}px)`]: {
			maxWidth: "100%",
		},
	},

	title: {
		color: theme.colorScheme === "dark" ? theme.white : theme.black,
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
	},

	logo: {
		color: theme.colorScheme === "dark" ? theme.white : theme.black,
		width: 120,
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
	},
}));

export default function Login() {
	const { classes } = useStyles();
	const { loginWithLink } = useAuth();
	const [email, setEmail] = React.useState("");
	const [loading, setLoading] = React.useState(false);
	const [linkSent, setLinkSent] = React.useState(false);
	const [error, setError] = React.useState("");

	const thisYear = new Date();
	let navigate = useNavigate();
	let location = useLocation();

	const schema = Yup.object().shape({
		email: Yup.string()
			.email("Ongeldige e-mail")
			.required("E-mail is verplicht"),
	});
	const form = useForm({
		initialValues: {
			email: "",
		},

		validate: yupResolver(schema),
	});

	let from = location.state?.from?.pathname || "/";

	if (isSignInWithEmailLink(auth, window.location.href)) {
		var mail = localStorage.getItem("emailForSignIn");
		/* 		console.log(mail);
		if (mail === null) {
			// User opened the link on a different device. To prevent session fixation
			// attacks, ask the user to provide the associated email again. For example:
			mail = window.prompt("Geef uw emailadres in ter bevestiging");
		} */
		// The client SDK will parse the code from the link for you.
		signInWithEmailLink(auth, mail, window.location.href)
			.then((result) => {
				window.localStorage.removeItem("emailForSignIn");
				window.location.pathname = "/";
				navigate(from, { replace: true });
			})
			.catch((error) => {});
	}

	const handleLogin = async (values) => {
		setError("");
		setLoading(true);
		await loginWithLink(values.email)
			.then((e) => {
				if (e.code !== 404) {
					setLoading(false);
					setLinkSent(true);
					switch (e) {
						case "auth/invalid-email":
							setError("Email niet gevonden");
							break;
						case "auth/user-disabled":
							setError("Account is gedeactiveerd");
							break;
						default:
					}
				} else {
					setError("Ongeldig email adres");
					setLoading(false);
				}
			})
			.catch((e) => console.log(e));
	};

	return (
		<div className={classes.wrapper}>
			<Paper className={classes.form} radius={0} p={30}>
				<Center>
					<Image
						width="70%"
						src={logo}
						alt=""
						withPlaceholder
						mb={30}
						ml={50}
					/>
				</Center>
				{!linkSent && (
					<form onSubmit={form.onSubmit((values) => handleLogin(values))}>
						<TextInput
							label="E-mailadres"
							placeholder="hello@gmail.com"
							size="md"
							required
							disabled={loading}
							{...form.getInputProps("email")}
						/>
						<Button
							fullWidth
							mt="xl"
							loading={loading}
							size="md"
							type="submit"
							/* 					onClick={async () => {
						await login();
						navigate(from, { replace: true });
					}} */
							leftIcon={<IconLock size={18} />}
						>
							Verstuur login link
						</Button>
						<Group mt={20} position="apart">
							<Text size="xs">© {thisYear.getFullYear()} - A² Advies BV</Text>
							<Anchor size="xs" href="https://docs.a2advies.be" target="_blank">
								Documentatie
							</Anchor>
						</Group>
					</form>
				)}
				{linkSent && (
					<Stack sx={{ width: "100%" }} align="center" mt={20}>
						<Checkmark size="xxLarge" color="#87D886" />
						<Text align="center">
							De email met uw logincode is verzonden naar {form.values.email}
						</Text>
						<Text color="dimmed">Tip: controleer ook uw ongewenste mail!</Text>
					</Stack>
				)}
			</Paper>
		</div>
	);
}
