import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getStorage } from "firebase/storage";

const app = initializeApp({
	apiKey: "AIzaSyCzHyNrTBaX34ib0zodKcrHIGBwHiD5smA",
	authDomain: "a2advies-app.firebaseapp.com",
	projectId: "a2advies-app",
	storageBucket: "a2advies-app.appspot.com",
	messagingSenderId: "918356024082",
	appId: "1:918356024082:web:2d2b3ac6367f686ab331cb",
});

const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app, "europe-west1");
const storage = getStorage(app);

if (window.location.hostname === "localhost") {
	console.log(
		"testing locally -- hitting local functions and firestore emulators"
	);
	//	connectAuthEmulator(auth, "http://localhost:9099");
	connectFunctionsEmulator(functions, "127.0.0.1", 5001);
	//connectFirestoreEmulator(db, "localhost", 8080);
}

export { auth, db, functions, storage };
export default app;
