import React from "react";
import { useApi } from "./contexts/APIContext";
import {
	DataGrid,
	highlightFilterValue,
	stringFilterFn,
	booleanFilterFn,
} from "mantine-data-grid";
import {
	MultiSelect,
	Container,
	Title,
	Text,
	Card,
	Group,
	Button,
	Drawer,
	TextInput,
	Checkbox,
	Stack,
	Badge,
	Anchor,
	Tooltip,
	ActionIcon,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { showNotification } from "@mantine/notifications";

import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { functions } from "./firebase";
import { httpsCallable } from "firebase/functions";
import {
	IconAt,
	IconCheck,
	IconPencil,
	IconPlus,
	IconStar,
	IconTrash,
	IconUser,
} from "@tabler/icons";

export default function Gebruikers() {
	const [opened, setOpened] = React.useState(false);
	const [loading, setLoading] = React.useState(false);
	const [error, setError] = React.useState("");
	const [data, setData] = React.useState([]);
	const [adminLoading, setAdminLoading] = React.useState(false);
	const [userLoading, setUserLoading] = React.useState(false);

	const {
		companies,
		userlist,
		setDossierFilterUser,
		loggedInCompany,
		allUsers,
	} = useApi();
	const navigate = useNavigate();

	const catFilter = (row, columnId, filter) => {
		const rowValue = String(row.getValue(columnId));
		return Array.isArray(filter) ? filter.includes(rowValue) : false;
	};
	catFilter.autoRemove = (val) => !val;
	catFilter.init = () => [];
	catFilter.element = function ({ filter, onFilterChange }) {
		return (
			<MultiSelect
				searchable
				data={userlist}
				value={filter || []}
				onChange={onFilterChange}
				placeholder="Filter"
			/>
		);
	};

	const form = useForm({
		initialValues: {
			email: "",
			naam: "",
			admin: false,
		},

		validate: (values) => ({
			email: /^\S+@\S+$/.test(values.email) ? null : "Ongeldig e-mailadres",
			naam: values.naam.length > 0 ? null : "Dit veld is verplicht",
		}),
	});

	const handleMakeAdmin = async (id) => {
		setAdminLoading(true);
		const makeAdmin = httpsCallable(functions, "makeAdmin");
		await makeAdmin({ id: id }).then((e) => {
			if (!e.data.error) {
				setAdminLoading(false);
			} else {
				setAdminLoading(false);
				console.log(e.data.errorMessage);
			}
		});
	};

	const handleMakeUser = async (id) => {
		setUserLoading(true);
		const makeAdmin = httpsCallable(functions, "makeUser");
		await makeAdmin({ id: id }).then((e) => {
			if (!e.data.error) {
				setUserLoading(false);
			} else {
				setUserLoading(false);
				console.log(e.data.errorMessage);
			}
		});
	};

	React.useEffect(() => {
		const withCompany = allUsers.map((i) => {
			const company = companies.find((e) => e.id === i.companyId);

			return { ...i, company: company.naam };
		});

		setData(withCompany);
	}, [allUsers, companies]);

	return (
		<>
			<Drawer
				opened={opened}
				onClose={() => {
					form.reset();
					setError("");
					setOpened(false);
				}}
				position="right"
				title="Nieuwe gebruiker"
				padding="xl"
				size="xl"
				styles={(theme) => ({
					title: {
						fontSize: "1.4rem",
						fontWeight: "700",
						color: theme.colors.blue[8],
					},
				})}
			>
				<form onSubmit={form.onSubmit((values) => console.log(values))}>
					<Stack justify="flex-start" spacing="lg">
						<TextInput
							withAsterisk
							label="Naam"
							icon={<IconUser size={14} />}
							{...form.getInputProps("naam")}
						/>
						<TextInput
							withAsterisk
							label="Email"
							icon={<IconAt size={14} />}
							{...form.getInputProps("email")}
						/>

						<Checkbox
							mt="md"
							label="Beheerder?"
							{...form.getInputProps("admin", { type: "checkbox" })}
						/>
						<Group position="right" mt="md">
							<Text size="xs" color="red">
								{error}
							</Text>
							<Button loading={loading} type="submit">
								Aanmaken
							</Button>
						</Group>
					</Stack>
				</form>
			</Drawer>
			<Container size="lg" py="lg">
				<Title sx={{ marginBottom: 30 }}>Gebruikers</Title>
				<Card shadow="xs" p="lg" radius="md">
					{allUsers.length > 0 ? (
						<DataGrid
							data={data}
							withPagination
							highlightOnHover
							styles={(theme) => ({
								thead: {
									"::after": {
										backgroundColor: theme.colors.blue[5],
									},
								},
								pagination_info: {
									fontSize: "14px",
								},
							})}
							withColumnFilters
							withSorting
							striped
							initialState={{
								sorting: [
									{
										id: "naam",
										desc: false,
									},
								],
								pagination: {
									pageSize: 25,
								},
							}}
							locale={{
								globalSearch: "Zoeken...",
								pageSize: "Gebruikers per pagina:",
								pagination: (firstRowNum, lastRowNum, maxRows) => (
									<>
										Gebruiker <b>{firstRowNum}</b> tot <b>{lastRowNum}</b> van{" "}
										<b>{maxRows}</b> weergegeven
									</>
								),
							}}
							empty={<Title order={4}>Geen gebruikers gevonden</Title>}
							onRow={(row) => ({
								style: { cursor: "pointer" },
							})}
							onCell={(cell) =>
								cell.column.id === "naam"
									? {
											onClick: () => {
												setDossierFilterUser(cell.row.original.naam);
												navigate(`/admin/bedrijfsdossiers`);
											},
									  }
									: {}
							}
							columns={[
								{
									accessorKey: "naam",
									header: "Naam",
									size: "20%",
									cell: (cell) => (
										<Tooltip
											label="Toon alle dossiers"
											withArrow
											position="right"
											transition="scale-x"
											openDelay={150}
										>
											<Text weight={500}>{cell.getValue()}</Text>
										</Tooltip>
									),
									filterFn: catFilter,
								},

								{
									accessorKey: "email",
									header: "E-mail",
									filterFn: stringFilterFn,
									size: "30%",
									cell: (cell) => (
										<Anchor href={`mailto:${cell.getValue()}`}>
											{cell.getValue()}
										</Anchor>
									),
								},
								{
									accessorKey: "company",
									header: "Bedrijf",
									filterFn: stringFilterFn,
									size: "30%",
								},
								{
									accessorKey: "admin",
									header: "Type",
									cell: (cell) =>
										cell.getValue() ? (
											<Badge size="xs">Beheerder</Badge>
										) : (
											<Badge size="xs" color="orange">
												Gebruiker
											</Badge>
										),
									size: "30%",
								},
							]}
						/>
					) : (
						<Text>
							<Link to="/">Maak een gebruiker aan</Link> om te beginnen
						</Text>
					)}
				</Card>
			</Container>
		</>
	);
}
