import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { MantineProvider } from "@mantine/core";
import { AuthProvider } from "./contexts/AuthContext";
import { APIProvider } from "./contexts/APIContext";
import { NotificationsProvider } from "@mantine/notifications";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<AuthProvider>
		<APIProvider>
			<MantineProvider
				withGlobalStyles
				withNormalizeCSS
				theme={{
					components: {
						Title: {
							styles: {
								root: {
									"&:is(h1)": { color: "#1971c2" },
									"&:is(h2)": { color: "#1971c2" },
								},
							},
						},
					},
				}}
			>
				<NotificationsProvider>
					<App />
				</NotificationsProvider>
			</MantineProvider>
		</APIProvider>
	</AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
