import {
	Container,
	Title,
	Timeline,
	Grid,
	Text,
	Anchor,
	Card,
	SimpleGrid,
	Divider,
	Group,
	Button,
	Loader,
} from "@mantine/core";
import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, functions } from "./firebase";
import {
	doc,
	onSnapshot,
	collectionGroup,
	query,
	where,
	getDocs,
} from "firebase/firestore";
import { useAuth } from "./contexts/AuthContext";
import {
	IconCheck,
	IconClock,
	IconCurrencyEuro,
	IconDownload,
	IconFileText,
	IconSend,
	IconTrash,
	IconUserSearch,
	IconX,
} from "@tabler/icons";
import dayjs from "dayjs";
import { httpsCallable } from "firebase/functions";
import { useApi } from "./contexts/APIContext";
import TLIcon from "./img/TL-icon-inverse.png";
import { showNotification } from "@mantine/notifications";

var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);
require("dayjs/locale/nl");
dayjs.locale("nl");

export default function ViewDossier() {
	const [dossier, setDossier] = React.useState({});
	const [loading, setLoading] = React.useState(false);
	const [loadingDownload, setLoadingDownload] = React.useState(false);
	const { bedrijfId, userType } = useAuth();
	const { prijzen } = useApi();
	const { id } = useParams();

	const navigate = useNavigate();

	React.useLayoutEffect(() => {
		if (bedrijfId !== undefined && userType !== "superuser") {
			const unsub = onSnapshot(
				doc(db, "companies", bedrijfId, "deals", id),
				(doc) => {
					let phase;
					switch (doc.data().fase) {
						case "Offerte verzonden":
							phase = 1;
							break;
						case "Inplannen inspectie":
							phase = 2;
							break;
						case "Rapportage":
							phase = 3;
							break;
						case "Facturatie":
							phase = 4;
							break;
						default:
							phase = 1;
							break;
					}

					setDossier({
						...doc.data(),
						phase: phase,
					});
				}
			);
			return unsub;
		}
		if (bedrijfId !== undefined && userType === "superuser") {
			const getData = async () => {
				const museums = query(
					collectionGroup(db, "deals"),
					where("docId", "==", id)
				);
				const querySnapshot = await getDocs(museums);
				querySnapshot.forEach((doc) => {
					let phase;
					switch (doc.data().fase) {
						case "Offerte verzonden":
							phase = 1;
							break;
						case "Inplannen inspectie":
							phase = 2;
							break;
						case "Rapportage":
							phase = 3;
							break;
						case "Facturatie":
							phase = 4;
							break;
						default:
							phase = 1;
							break;
					}

					setDossier({
						...doc.data(),
						phase: phase,
					});
				});
			};
			getData();
		}
	}, [id, bedrijfId]);

	const deleteDeal = async () => {
		const del = httpsCallable(functions, "deleteDeal");
		setLoading(true);
		await del({
			id: id,
		}).then((e) => {
			setLoading(false);
			!e.data.error ? navigate("/dossiers") : console.log(e.data.errorMessage);
		});
	};

	const getInventarisPDF = async () => {
		setLoadingDownload(true);
		const getPDF = httpsCallable(functions, "getInventaris");
		await getPDF({
			id: id,
		})
			.then((e) => {
				const url = e.data.url;

				//download url to disk
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "inventaris.pdf"); //or any other extension
				link.setAttribute("target", "_blank"); // opens link in a new tab
				document.body.appendChild(link);
				link.click();
				link.remove();
				setLoadingDownload(false);
			})
			.catch((e) => {
				setLoadingDownload(false);
				showNotification({
					title: "Fout bij downloaden attest",
					message: `Er is een fout opgetreden bij het downloaden van het attest. Neem contact op met uw beheerder.`,
					icon: <IconX />,

					color: "red",
				});
			});
	};

	return (
		<Container size="lg" py="lg">
			<Title sx={{ marginBottom: 50 }}>
				{dossier.offerteNummer}: {dossier.werfAdres}
			</Title>
			<Grid grow gutter="xl">
				<Grid.Col span={4}>
					<Timeline active={dossier.phase !== undefined ? dossier.phase : 1}>
						<Timeline.Item
							bullet={<IconSend size={12} />}
							title="Offerte verzonden"
						>
							<Text color="dimmed" size="sm">
								Offerte gemaild naar{" "}
								<Anchor href={`mailto:${dossier.email}`} inherit>
									{dossier.email}
								</Anchor>
							</Text>
							<Text size="xs" mt={4}>
								{dossier.faseTimeline !== undefined &&
									dossier.offerteAanmaakDatum !== undefined &&
									dayjs()
										.locale("nl-be")
										.to(dayjs(dossier.offerteAanmaakDatum.toDate()))}
							</Text>
						</Timeline.Item>
						{dossier.won === "open" && (
							<Timeline.Item
								bullet={<IconClock size={12} />}
								title="Wachten op beslissing"
								lineVariant={dossier.phase < 2 ? "dashed" : "solid"}
							>
								<Text color="dimmed" size="sm">
									De klant moet de offerte goedkeuren alvorens de werken
									ingepland worden.
								</Text>
								<Text size="xs" mt={4}>
									nu
								</Text>
							</Timeline.Item>
						)}
						{dossier.won === "won" && (
							<Timeline.Item
								bullet={<IconCheck size={12} />}
								title="Offerte goedgekeurd"
								color="green"
								lineVariant={dossier.phase < 2 ? "dashed" : "solid"}
							>
								<Text color="dimmed" size="sm">
									De klant heeft de offerte goedgekeurd. We nemen zo snel
									mogelijk contact op om de werken in te plannen.
								</Text>
								<Text size="xs" mt={4}>
									{dossier.faseTimeline !== undefined &&
										dossier.faseTimeline.find((e) => e.fase === "Aanvaard") !==
											undefined &&
										dayjs()
											.locale("nl-be")
											.to(
												dayjs(
													dossier.faseTimeline
														.find((e) => e.fase === "Aanvaard")
														.time.toDate()
												)
											)}
								</Text>
							</Timeline.Item>
						)}
						{dossier.won === "lost" && (
							<Timeline.Item
								bullet={<IconX size={12} />}
								title="Offerte afgekeurd"
								lineVariant={dossier.phase < 2 ? "dashed" : "solid"}
								color="red"
							>
								<Text color="dimmed" size="sm">
									De klant heeft de offerte afgekeurd.
								</Text>
								<Text size="xs" mt={4}>
									{dossier.faseTimeline !== undefined &&
										dossier.faseTimeline.find((e) => e.fase === "Geweigerd") !==
											undefined &&
										dayjs()
											.locale("nl-be")
											.to(
												dayjs(
													dossier.faseTimeline
														.find((e) => e.fase === "Geweigerd")
														.time.toDate()
												)
											)}
								</Text>
							</Timeline.Item>
						)}
						{dossier.won !== "lost" && (
							<Timeline.Item
								bullet={<IconUserSearch size={12} />}
								title="Inplannen inspectie"
								lineVariant={dossier.phase < 3 ? "dashed" : "solid"}
							>
								<Text color="dimmed" size="sm">
									We hebben een deskundige ingepland{" "}
									{dossier.inspectiedag
										? `op ${dayjs(dossier.inspectiedag).format(
												"DD MMMM YYYY"
										  )} ${
												dossier.inspectieuur
													? String(dossier.inspectieuur).toLowerCase()
													: ""
										  }`
										: ""}
								</Text>
								<Text size="xs" mt={4}>
									{dossier.faseTimeline !== undefined &&
										dossier.faseTimeline.find(
											(e) => e.fase === "Inplannen inspectie"
										) !== undefined &&
										dayjs()
											.locale("nl-be")
											.to(
												dayjs(
													dossier.faseTimeline
														.find((e) => e.fase === "Inplannen inspectie")
														.time.toDate()
												)
											)}
								</Text>
							</Timeline.Item>
						)}
						{dossier.won !== "lost" && (
							<Timeline.Item
								bullet={<IconFileText size={12} />}
								title="Rapportage"
								lineVariant={dossier.phase < 4 ? "dashed" : "solid"}
							>
								<Text color="dimmed" size="sm">
									Onze deskundige maakt het rapport op
								</Text>
								<Text size="xs" mt={4}>
									{" "}
									{dossier.faseTimeline !== undefined &&
										dossier.faseTimeline.find(
											(e) => e.fase === "Rapportage"
										) !== undefined &&
										dayjs()
											.locale("nl-be")
											.to(
												dayjs(
													dossier.faseTimeline
														.find((e) => e.fase === "Rapportage")
														.time.toDate()
												)
											)}
								</Text>
							</Timeline.Item>
						)}
						{dossier.won !== "lost" && (
							<Timeline.Item
								bullet={<IconCurrencyEuro size={12} />}
								title="Afgerond"
								color="green"
								lineVariant={dossier.phase < 5 ? "dashed" : "solid"}
							>
								<Text color="dimmed" size="sm">
									De rapportage en de factuur is aan de klant bezorgd
								</Text>

								<Text size="xs" mt={4}>
									{dossier.faseTimeline !== undefined &&
										dossier.faseTimeline.find(
											(e) => e.fase === "Facturatie"
										) !== undefined &&
										dayjs()
											.locale("nl-be")
											.to(
												dayjs(
													dossier.faseTimeline
														.find((e) => e.fase === "Facturatie")
														.time.toDate()
												)
											)}
								</Text>
								{dossier.phase >= 4 && (
									<Button
										mt="sm"
										size="xs"
										leftIcon={<IconDownload size="14" />}
										loading={loadingDownload}
										onClick={() => getInventarisPDF()}
									>
										<Text size="sm">Download attest</Text>
									</Button>
								)}
							</Timeline.Item>
						)}
					</Timeline>
				</Grid.Col>
				<Grid.Col span={8}>
					<Card shadow="xs" p="lg" radius="md">
						<Grid grow gutter="xl">
							<Grid.Col span={4}>
								<Text weight={500}>Naam</Text>
							</Grid.Col>
							<Grid.Col span={8}>
								<Text>{dossier.naam}</Text>
							</Grid.Col>
							{dossier.klant !== "particulier" && (
								<>
									<Grid.Col span={4}>
										<Text weight={500}>BTW-nummer</Text>
									</Grid.Col>
									<Grid.Col span={8}>
										<Text>{dossier.btw}</Text>
									</Grid.Col>
								</>
							)}
							<Grid.Col span={4}>
								<Text weight={500}>E-mail</Text>
							</Grid.Col>
							<Grid.Col span={8}>
								<Anchor href={`mailto:${dossier.email}`}>
									{dossier.email}
								</Anchor>
							</Grid.Col>
							<Grid.Col span={4}>
								<Text weight={500}>Telefoon</Text>
							</Grid.Col>
							<Grid.Col span={8}>
								<Anchor href={`tel:${dossier.tel}`}>{dossier.tel}</Anchor>
							</Grid.Col>
							<Grid.Col span={4}>
								<Text weight={500}>Facturatieadres</Text>
							</Grid.Col>
							<Grid.Col span={8}>
								<Text>{dossier.facturatieAdres}</Text>
							</Grid.Col>
							<Grid.Col span={4}>
								<Text weight={500}>Werfadres</Text>
							</Grid.Col>
							<Grid.Col span={8}>
								<Text>{dossier.werfAdres}</Text>
							</Grid.Col>
							<Grid.Col span={4}>
								<Text weight={500}>Woningtype</Text>
							</Grid.Col>
							<Grid.Col span={8}>
								<Text style={{ textTransform: "capitalize" }}>
									{prijzen.find((e) => e.value === dossier.typeWoning)?.label}
								</Text>
							</Grid.Col>
							<Grid.Col span={4}>
								<Text weight={500}>Opmerkingen</Text>
							</Grid.Col>
							<Grid.Col span={8}>
								<Text>{dossier.opmerkingen}</Text>
							</Grid.Col>

							{dossier.commissieBedrag > 0 && (
								<>
									<Grid.Col span={12}>
										<Divider />
									</Grid.Col>
									<Grid.Col span={4}>
										<Text weight={500}>Commissie</Text>
									</Grid.Col>
									<Grid.Col span={8}>
										<Text style={{ textTransform: "capitalize" }}>
											{dossier.commissieBedrag} EUR
										</Text>
									</Grid.Col>
								</>
							)}
						</Grid>
					</Card>

					<Group position="right" mt={20}>
						{userType === "superuser" && (
							<Button
								leftIcon={<img src={TLIcon} alt="" width={20} />}
								styles={(theme) => ({
									root: {
										backgroundColor: "#00B2B2",
										"&:hover": {
											backgroundColor: "#009999",
										},
									},
								})}
								loading={loading}
								onClick={() =>
									window.open(
										`https://focus.teamleader.eu/web/deals/${id}`,
										"_blank"
									)
								}
							>
								Bekijk in Teamleader
							</Button>
						)}
						{dossier.phase <= 1 && dossier.won !== "won" && (
							<Button
								leftIcon={<IconTrash size={14} />}
								color="red"
								loading={loading}
								onClick={() => deleteDeal()}
							>
								Verwijderen
							</Button>
						)}
					</Group>
				</Grid.Col>
			</Grid>
		</Container>
	);
}
