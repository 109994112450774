import React from "react";
import { useApi } from "./contexts/APIContext";
import {
	DataGrid,
	highlightFilterValue,
	createDateFilter,
	stringFilterFn,
} from "mantine-data-grid";
import {
	MultiSelect,
	Container,
	Title,
	Text,
	Card,
	Tooltip,
	ThemeIcon,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import {
	IconCheck,
	IconCurrencyEuro,
	IconFileText,
	IconSend,
	IconUserSearch,
	IconX,
} from "@tabler/icons";

const dateFilter = createDateFilter({
	title: "Datum",
	placeholder: "Selecteer datum",
	labels: {
		bet: "Tussen",
		beteq: "Van tot",
		nbet: "Niet tussen",
		eq: "Gelijk aan",
		gt: "Later dan",
		gte: "Later dan of gelijk",
		lt: "Kleiner als",
		lte: "Kleiner of gelijk",
		neq: "Is niet",
	},
});

const faseFilter = (row, columnId, filter) => {
	const rowValue = String(row.getValue(columnId));
	return Array.isArray(filter) ? filter.includes(rowValue) : false;
};
faseFilter.autoRemove = (val) => !val;
faseFilter.init = () => [];
faseFilter.element = function ({ filter, onFilterChange }) {
	return (
		<MultiSelect
			searchable
			data={[
				{ value: "Offerte verzonden", label: "Offerte verzonden" },
				{ value: "Geweigerd", label: "Geweigerd" },
				{ value: "Aanvaard", label: "Aanvaard" },
				{ value: "Inplannen inspectie", label: "Inplannen inspectie" },
				{ value: "Rapportage", label: "Rapportage" },
				{ value: "Facturatie", label: "Facturatie" },
			]}
			value={filter || []}
			onChange={onFilterChange}
			placeholder="Selecteer waarde"
		/>
	);
};

export default function BedrijfsDossiers() {
	const { deals, dossierFilterUser, userlist } = useApi();
	const navigate = useNavigate();

	const catFilter = (row, columnId, filter) => {
		const rowValue = String(row.getValue(columnId));
		console.log(rowValue);
		return Array.isArray(filter) ? filter.includes(rowValue) : false;
	};
	catFilter.autoRemove = (val) => !val;
	catFilter.init = () => [];
	catFilter.element = function ({ filter, onFilterChange }) {
		return (
			<MultiSelect
				searchable
				data={userlist}
				value={filter || []}
				onChange={onFilterChange}
				placeholder="Filter"
			/>
		);
	};

	return (
		<Container size="lg" py="lg">
			<Title sx={{ marginBottom: 30 }}>Bedrijfsdossiers</Title>
			<Card shadow="xs" p="lg" radius="md">
				{deals.length > 0 ? (
					<DataGrid
						data={deals}
						styles={(theme) => ({
							thead: {
								"::after": {
									backgroundColor: theme.colors.blue[5],
								},
							},
							pagination_info: {
								fontSize: "14px",
							},
						})}
						highlightOnHover
						withPagination
						withColumnFilters
						withSorting
						initialState={{
							sorting: [
								{
									id: "datumRaw",
									desc: true,
								},
							],
							columnFilters:
								dossierFilterUser !== ""
									? [
											{
												id: "maker",
												value: {
													value: dossierFilterUser,
												},
											},
									  ]
									: null,
						}}
						locale={{
							globalSearch: "Zoeken...",
							pageSize: "Dossiers per pagina:",
							pagination: (firstRowNum, lastRowNum, maxRows) => (
								<>
									Dossier <b>{firstRowNum}</b> tot <b>{lastRowNum}</b> van{" "}
									<b>{maxRows}</b> weergegeven
								</>
							),
						}}
						empty={<Title order={4}>Geen dossiers gevonden</Title>}
						onRow={(row) => ({
							onClick: () => navigate(`${row.original.id}`),
							style: { cursor: "pointer" },
						})}
						columns={[
							{
								accessorKey: "datumRaw",
								header: "Datum",
								filterFn: dateFilter,
								size: "50%",
								cell: (cell) =>
									dayjs(cell.getValue())
										.locale("nl-be")
										.format("DD MMM YYYY HH:mm"),
							},
							{
								accessorKey: "naam",
								header: "Naam",
								size: "20%",
								cell: highlightFilterValue,
								filterFn: stringFilterFn,
							},
							{
								accessorKey: "werfAdres",
								header: "Werfadres",
								filterFn: stringFilterFn,
								size: "30%",
								cell: highlightFilterValue,
							},
							{
								accessorKey: "fase",
								header: "Fase",
								size: "30%",
								filterFn: faseFilter,
								cell: (cell) =>
									cell.getValue() === "Offerte verzonden" ? (
										<Tooltip
											label="Verzonden"
											position="right"
											withArrow
											openDelay={150}
										>
											<ThemeIcon variant="light" size="sm" radius="xl">
												<IconSend size={12} />
											</ThemeIcon>
										</Tooltip>
									) : cell.getValue() === "Geweigerd" ? (
										<Tooltip
											label="Geweigerd"
											position="right"
											withArrow
											openDelay={150}
										>
											<ThemeIcon
												variant="light"
												size="sm"
												radius="xl"
												color="red"
											>
												<IconX size={12} />
											</ThemeIcon>
										</Tooltip>
									) : cell.getValue() === "Aanvaard" ? (
										<Tooltip
											label="Aanvaard"
											position="right"
											withArrow
											openDelay={150}
										>
											<ThemeIcon
												variant="light"
												size="sm"
												radius="xl"
												color="green"
											>
												<IconCheck size={12} />
											</ThemeIcon>
										</Tooltip>
									) : cell.getValue() === "Inplannen inspectie" ? (
										<Tooltip
											label="Inplannen inspectie"
											position="right"
											withArrow
											openDelay={150}
										>
											<ThemeIcon
												variant="light"
												size="sm"
												radius="xl"
												color="orange"
											>
												<IconUserSearch size={12} />
											</ThemeIcon>
										</Tooltip>
									) : cell.getValue() === "Rapportage" ? (
										<Tooltip
											label="Rapportage"
											position="right"
											withArrow
											openDelay={150}
										>
											<ThemeIcon
												variant="light"
												size="sm"
												radius="xl"
												color="grape"
											>
												<IconFileText size={12} />
											</ThemeIcon>
										</Tooltip>
									) : cell.getValue() === "Facturatie" ? (
										<Tooltip
											label="Factuur verzonden"
											position="right"
											withArrow
											openDelay={150}
										>
											<ThemeIcon
												variant="light"
												size="sm"
												radius="xl"
												color="dark"
											>
												<IconCurrencyEuro size={12} />
											</ThemeIcon>
										</Tooltip>
									) : null,
							},
							{
								accessorKey: "maker",
								header: "Maker",
								filterFn: stringFilterFn,
								size: "30%",
							},
						]}
					/>
				) : (
					<Text>
						<Link to="/">Maak een dossier aan</Link> om te beginnen
					</Text>
				)}
			</Card>
		</Container>
	);
}
