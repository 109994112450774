import {
	Text,
	Progress,
	Card,
	createStyles,
	Stack,
	Tooltip,
} from "@mantine/core";
import dayjs from "dayjs";
import { useApi } from "../contexts/APIContext";
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var isSameOrAfter = require("dayjs/plugin/isSameOrAfter");

dayjs.extend(quarterOfYear);
dayjs.extend(isSameOrAfter);

const useStyles = createStyles((theme) => ({
	card: {
		backgroundImage: `linear-gradient(-60deg, ${
			theme.colors[theme.primaryColor][4]
		} 0%, ${theme.colors[theme.primaryColor][7]} 100%)`,
	},

	title: {
		color: theme.fn.rgba(theme.white, 0.65),
	},

	stats: {
		color: theme.white,
	},

	progressBar: {
		backgroundColor: theme.white,
	},

	progressTrack: {
		backgroundColor: theme.fn.rgba(theme.white, 0.4),
	},
}));

export default function CompanyOverview() {
	const { classes } = useStyles();
	const date = new Date();

	const { deals } = useApi();
	const myDeals = deals;
	const quarter = dayjs(date).quarter();
	const startDate = dayjs().startOf("quarter").toDate();
	const endDate = dayjs().endOf("quarter").toDate();

	const quarterDeals = myDeals.filter(
		(e) =>
			dayjs(e.offerteAanmaakDatum.toDate()).isSameOrAfter(dayjs(startDate)) &&
			dayjs(e.offerteAanmaakDatum.toDate()).isBefore(dayjs(endDate))
	);
	const verzonden = myDeals.filter(
		(e) =>
			e.fase === "Offerte verzonden" &&
			e.faseTimeline.findIndex(
				(i) =>
					i.fase === "Offerte verzonden" &&
					dayjs(i.time.toDate()).isSameOrAfter(dayjs(startDate)) &&
					dayjs(i.time.toDate()).isBefore(dayjs(endDate))
			) > -1
	);
	const aanvaard = myDeals.filter(
		(e) =>
			e.fase === "Aanvaard" &&
			e.faseTimeline.findIndex(
				(i) =>
					i.fase === "Aanvaard" &&
					dayjs(i.time.toDate()).isSameOrAfter(dayjs(startDate)) &&
					dayjs(i.time.toDate()).isBefore(dayjs(endDate))
			) > -1
	);
	const geweigerd = myDeals.filter(
		(e) =>
			e.fase === "Geweigerd" &&
			e.faseTimeline.findIndex(
				(i) =>
					i.fase === "Geweigerd" &&
					dayjs(i.time.toDate()).isSameOrAfter(dayjs(startDate)) &&
					dayjs(i.time.toDate()).isBefore(dayjs(endDate))
			) > -1
	);
	const ingepland = myDeals.filter(
		(e) =>
			e.fase === "Inplannen inspectie" &&
			e.faseTimeline.findIndex(
				(i) =>
					i.fase === "Inplannen inspectie" &&
					dayjs(i.time.toDate()).isSameOrAfter(dayjs(startDate)) &&
					dayjs(i.time.toDate()).isBefore(dayjs(endDate))
			) > -1
	);
	const rapportage = myDeals.filter(
		(e) =>
			e.fase === "Rapportage" &&
			e.faseTimeline.findIndex(
				(i) =>
					i.fase === "Rapportage" &&
					dayjs(i.time.toDate()).isSameOrAfter(dayjs(startDate)) &&
					dayjs(i.time.toDate()).isBefore(dayjs(endDate))
			) > -1
	);
	const facturatie = myDeals.filter(
		(e) =>
			e.fase === "Facturatie" &&
			e.faseTimeline.findIndex(
				(i) =>
					i.fase === "Facturatie" &&
					dayjs(i.time.toDate()).isSameOrAfter(dayjs(startDate)) &&
					dayjs(i.time.toDate()).isBefore(dayjs(endDate))
			) > -1
	);

	var commissieTotaal = facturatie.reduce(function (prev, cur) {
		return prev + cur.commissieBedrag;
	}, 0);

	return (
		<Card withBorder radius="md" p="xl" className={classes.card}>
			<Stack>
				<Text
					size="xs"
					transform="uppercase"
					weight={700}
					className={classes.title}
				>
					Bedrijfsoverzicht Q{quarter}/{date.getFullYear()}
				</Text>
				<Progress
					radius="xl"
					size="xl"
					sections={[
						{
							value: (geweigerd.length / myDeals.length) * 100,
							color: "red",
							tooltip: `Geweigerd – ${geweigerd.length}`,
						},
						{
							value: (verzonden.length / myDeals.length) * 100,
							color: "pink",
							tooltip: `Onbeslist – ${verzonden.length}`,
						},
						{
							value: (aanvaard.length / myDeals.length) * 100,
							color: "green",
							tooltip: `Aanvaard – ${aanvaard.length}`,
						},
						{
							value: (ingepland.length / myDeals.length) * 100,
							color: "orange",
							tooltip: `Ingepland – ${ingepland.length}`,
						},
						{
							value: (rapportage.length / myDeals.length) * 100,
							color: "grape",
							tooltip: `Rapportage – ${rapportage.length}`,
						},
						{
							value: (facturatie.length / myDeals.length) * 100,
							color: "teal",
							tooltip: `Facturatie – ${facturatie.length}`,
						},
					]}
				/>
				<Text
					size="xs"
					transform="uppercase"
					weight={700}
					className={classes.title}
				>
					Aantal dossiers: {quarterDeals.length}
				</Text>
				<Tooltip
					label="Het commissiesaldo wordt bepaald adhv het aantal gefactureerde dossiers dit kwartaal"
					multiline
					withArrow
					openDelay={250}
				>
					<Text
						size="xs"
						transform="uppercase"
						weight={700}
						className={classes.title}
					>
						Commissiesaldo: {commissieTotaal} EUR
					</Text>
				</Tooltip>
			</Stack>
		</Card>
	);
}
