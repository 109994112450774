import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import BedrijfsDossiers from "./BedrijfsDossiers";
import Gebruikers from "./Gebruikers";
import MijnDossiers from "./MijnDossiers";
import NieuwDossier from "./NieuwDossier";
import Workspace from "./Workspace";
import { useAuth } from "./contexts/AuthContext";
import Login from "./Login";
import ViewDossier from "./ViewDossier";
import AlleDossiers from "./AlleDossiers";
import AlleBedrijven from "./AlleBedrijven";
import Settings from "./Settings";
import Bestelbonnen from "./Bestelbonnen";
import AlleBestelbonnen from "./AlleBestelbonnen";
import AlleGebruikers from "./AlleGebruikers";

export default function App() {
	const { currentUser, userType } = useAuth();
	return (
		<BrowserRouter>
			{currentUser && (
				<Workspace>
					<Routes>
						<>
							<Route path="/" index element={<NieuwDossier />} />
							<Route path="/dossiers" element={<MijnDossiers />} />
							<Route path="/dossiers/:id" element={<ViewDossier />} />
							<Route
								path="/admin/bedrijfsdossiers/:id"
								element={<ViewDossier />}
							/>
							<Route
								path="/admin/bedrijfsdossiers"
								element={<BedrijfsDossiers />}
							/>
							<Route path="/admin/bestelbonnen" element={<Bestelbonnen />} />
							<Route path="/admin/gebruikers" element={<Gebruikers />} />
							{userType === "superuser" && (
								<>
									<Route path="/app/dossiers" element={<AlleDossiers />} />
									<Route path="/app/dossiers/:id" element={<ViewDossier />} />
									<Route path="/app/users" element={<AlleGebruikers />} />
									<Route path="/app/bedrijven" element={<AlleBedrijven />} />
									<Route path="/app/settings" element={<Settings />} />
									<Route
										path="/app/allebestelbonnen"
										element={<AlleBestelbonnen />}
									/>
								</>
							)}
						</>
					</Routes>
				</Workspace>
			)}
			{!currentUser && (
				<Routes>
					<Route path="/" index element={<Login />} />
				</Routes>
			)}
		</BrowserRouter>
	);
}
