import React from "react";
import {
	Container,
	Title,
	Card,
	Button,
	Badge,
	Group,
	Flex,
	Stack,
	Switch,
	LoadingOverlay,
	Skeleton,
	NumberInput,
	TextInput,
} from "@mantine/core";
import {
	IconCheck,
	IconPercentage,
	IconPlugConnected,
	IconX,
} from "@tabler/icons";
import { db, functions } from "./firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useForm } from "@mantine/form";

export default function Settings() {
	const [settings, setSettings] = React.useState({});
	const [loading, setLoading] = React.useState(true);
	const connectTeamleader = (e) => {
		e.preventDefault();
		const url = `https://focus.teamleader.eu/oauth2/authorize?client_id=3412a33b99237d0ee7ab0f98ee133007&response_type=code&redirect_uri=https:%2F%2Feurope-west1-a2advies-app.cloudfunctions.net%2FconnectTeamleader`;
		window.location.href = url;
	};


	React.useEffect(() => {
		setLoading(true);
		const unsub = onSnapshot(doc(db, "general", "teamleader"), (doc) => {
			setSettings(doc.data());
		});

		const promotie = onSnapshot(doc(db, "general", "promotie"), (doc) => {
			promotieForm.setValues(doc.data());
			setLoading(false);
		});

		return () => {
			unsub();
			promotie();
		};
	}, []);

	const promotieForm = useForm({
		initialValues: {
			active: false,
			percentage: 0,
			line_text: "",
		},

		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
		},
	});

	return (
		<Container size="lg" py="lg">
			<Title sx={{ marginBottom: 30 }}>Instellingen</Title>
			<Stack>
				{/* <Card shadow="xs" p="lg" radius="md">
					<Flex
						justify="flex-start"
						align="center"
						direction="row"
						wrap="no-wrap"
						gap="md"
						mb={20}
					>
						<Title order={2}>Promotie</Title>
						{promotieForm.values.active ? (
							<Skeleton visible={loading} width={70} height={30}>
								<Badge color={"teal"}>Actief</Badge>
							</Skeleton>
						) : (
							<Skeleton visible={loading} width={70} height={30}>
								<Badge color={"red"}>Niet actief</Badge>
							</Skeleton>
						)}
					</Flex>
					<form
						onSubmit={promotieForm.onSubmit((values) => console.log(values))}
					>
						<Skeleton visible={loading} height={30}>
							<Group sx={{ width: "100%" }}>
								<Switch
									{...promotieForm.getInputProps("active", {
										type: "checkbox",
									})}
									color="teal"
									size="md"
									onLabel={<IconCheck size={16} stroke={2.5} color="teal" />}
									offLabel={<IconX size={16} stroke={2.5} color="dim" />}
									//	onChange={(event) => setChecked(event.currentTarget.checked)}
								/>
								<NumberInput
									stepHoldDelay={500}
									stepHoldInterval={100}
									noClampOnBlur
									disabled={!promotieForm.values.active}
									max={100}
									min={1}
									formatter={(value) =>
										!Number.isNaN(parseFloat(value))
											? `-${value} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
											: " %"
									}
									{...promotieForm.getInputProps("percentage")}
									sx={{ width: 100 }}
								/>
								<TextInput
									disabled={!promotieForm.values.active}
									{...promotieForm.getInputProps("line_text")}
									sx={{ width: "70%" }}
								/>
							</Group>
						</Skeleton>
					</form>
				</Card> */}
				<Card shadow="xs" p="lg" radius="md">
					<Title order={2} mb={20}>
						Teamleader
					</Title>

					<Button color="teal" leftIcon={<IconCheck />}>
						Verbonden
					</Button>
				</Card>
			</Stack>
		</Container>
	);
}
